import React, { useState } from 'react'
import cx from 'classnames'
import jsonp from 'jsonp'
import queryString from 'query-string'
import htmlParser from 'react-html-parser'
import is from 'react-jss'

import IconButton from '../IconButton'
import Icon from '../Icon'
import Link from '../Link'

import style from './style'

const Newsletter = ({
    classes,
    locale,
    heading,
    inputPlaceholder,
    buttonText,
    checkboxLabel,
    readMore,
    messages,
}) => {
    const [ accepted, setAccepted ] = useState(false)
    const [ status, setStatus ] = useState('')
    const [ email, setEmail ] = useState('')
    const [ showReadMore, setShowReadMore ] = useState(false)

    const handleReadMoreClick = (e) => {
        e.preventDefault()
        setShowReadMore(true)
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        
        if (accepted) {
            setStatus('loading')

            jsonp(`https://krafton.us7.list-manage.com/subscribe/post-json?u=888520ec6ea9406adc545b93c&id=b928ac4d24&${queryString.stringify({ EMAIL: email })}`, { param: 'c' }, (err, data) => {
                if (err) {
                    setStatus('error')
                } else {
                    if (data.result === 'error') {
                        if (data.msg.includes('is already subscribed')) {
                            setStatus('existing')
                        } else {
                            setStatus('error')
                        }
                    } else {
                        setStatus('success')
                    }
                }
            })
        } else {
            setStatus('missing')
        }
    }

    return (
        <div className={ cx(classes.newsletter, { loading: status === 'loading' }) }>
            { status === 'success' && (
                <IconButton
                    color="yellow"
                    name="crosshair"
                    size="lg"
                    iconSize="xl"
                    classNames={{ btn: classes.crosshair }}
                    disabled
                />
            ) }

            <div className={ cx(classes.readMore, { open: showReadMore }) }>
                <div className={classes.readMoreInner}>
                    <IconButton
                        name="close"
                        iconSize="sm"
                        size="sm"
                        color="white"
                        classNames={{ btn: classes.readMoreClose }}
                        clickHandler={ () => setShowReadMore(false) }
                    />
                    <p className={classes.readMoreText}>{ htmlParser(readMore.text) } <Link external={true} to={ `/${locale}/privacy` } className={classes.readMoreLink}>{ readMore.link }</Link></p>
                </div>
            </div>

            <h2 className={classes.heading}>{ status === 'success' ? heading.success : heading.default }</h2>

            { status === 'success' ? (
                <div className={classes.messages}>
                    <p className={classes.message}>{ messages.success }</p>
                </div>
            ) : [
                <form key="form" onSubmit={ (e) => handleSubmit(e) }>
                    <div className={classes.fields}>
                        <label htmlFor="email" className={classes.inputLabel}>Email</label>
                        <input
                            { ...{
                                id: 'email',
                                type: 'email',
                                name: 'email',
                                required: true,
                                value: email,
                                placeholder: inputPlaceholder,
                                className: classes.input,
                                onChange: ({ currentTarget: { value }}) => setEmail(value),
                            } }
                        />
                        <button
                            { ...{
                                type: 'submit',
                                placeholder: inputPlaceholder,
                                className: classes.btn,
                            } }
                        >
                            <span>{ buttonText }</span>
                            <Icon iconSize="sm" color="white" name="arrowRight" className={classes.btnIcon} />
                        </button>
                    </div>

                    <label className={classes.checkboxWrapper}>
                        <div className={ cx(classes.checkboxSquare, { accepted }) } />
                        <input
                            { ...{
                                type: 'checkbox',
                                name: 'terms',
                                checked: accepted,
                                onChange: ({ currentTarget: { checked }}) => setAccepted(checked),
                                className: classes.checkbox,
                            } }
                        />
                        <span className={classes.checkboxLabel}>{ checkboxLabel } <span className={classes.readMoreLabel} onClick={ (e) => handleReadMoreClick(e) }>{ readMore.label }</span></span>
                    </label>
                </form>,
                (['error', 'existing', 'missing'].indexOf(status) >= 0) && (
                    <div key="messages" className={classes.messages}>
                        <p className={classes.message}>{ messages[status] }</p>
                    </div>
                )
            ] }
        </div>
    )
}

export default is(style)(Newsletter)
