export default ({
    spaces,
    typography,
    colors,
    breakpoints,
    buttons,
}) => ({
    key: { name: 'Newsletter' },

    newsletter: {
        padding: [spaces[5], spaces[2]],
        position: 'relative',
        backgroundColor: colors.boulder,
        backgroundImage: 'url(/newsletter-bg.jpg)',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        textAlign: 'center',

        [breakpoints.md]: {
            padding: [spaces[5], spaces[3]],
        },

        [breakpoints.lg]: {
            padding: [spaces[5]*2, spaces[5]],
        },

        '&::after': {
            display: 'block',
            width: '100%',
            height: '100%',
            position: 'absolute',
            top: 0,
            left: 0,
            backgroundColor: colors.blue,
            transition: 'all ease-in-out .1s',
            opacity: 0,
            visibility: 'hidden',
            pointerEvents: 'none',
            zIndex: 2,
            content: '""',
        },

        '&.loading': {
            '&::after': {
                opacity: .85,
                visibility: 'visible',
                pointerEvents: 'auto',
            },
        },
    },

    crosshair: {
        marginBottom: spaces[2],
    },

    heading: {
        ...typography.t1,
        textTransform: 'uppercase',
        fontWeight: 700,
        color: colors.white,
    },

    fields: {
        width: '100%',
        maxWidth: 600,
        margin: [spaces[3], 'auto', 0],
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',

        [breakpoints.sm]: {
            flexDirection: 'row',
            alignItems: 'center',
        },
    },

    inputLabel: {
        display: 'none',
    },

    input: {
        flex: 1,
        padding: [spaces[1], spaces[2]],
        ...typography.md,
        color: colors.boulder,
        backgroundColor: colors.black,
    },

    btn: {
        ...buttons.base,
        ...buttons.md,
        marginTop: spaces[1],
        backgroundColor: colors.blonde,
        color: colors.white,

        '&:not([disabled]):hover': {
            backgroundColor: colors.white,
            color: colors.blonde,

            '& .icon-fill': {
                fill: colors.blonde,
            },
        },

        [breakpoints.sm]: {
            marginTop: 0,
            marginLeft: spaces[0],
        },

        [breakpoints.md]: {
            marginLeft: spaces[1],
        },
    },

    btnIcon: {
        marginLeft: spaces[0],
    },

    checkboxWrapper: {
        display: 'flex',
        alignItems: 'flex-start',
        maxWidth: 600,
        margin: [spaces[2], 'auto', 0],
        textAlign: 'left',
        userSelect: 'none',
        cursor: 'pointer',
    },

    checkboxSquare: {
        display: 'inline-block',
        width: 24,
        minWidth: 24,
        height: 24,
        transform: 'translate3d(0, 5px, 0)',
        backgroundColor: colors.white,
        border: `6px solid ${colors.white}`,
        borderRadius: 3,

        '&.accepted': {
            borderColor: colors.blonde,
        },
    },

    checkbox: {
        display: 'none',
    },

    checkboxLabel: {
        display: 'inline-block',
        marginLeft: spaces[1],
        ...typography.md,
        textTransform: 'uppercase',
        color: colors.boulder,
    },

    readMoreLabel: {
        display: 'inline-block',
        color: colors.blonde,
    },

    messages: {
        margin: [spaces[3], 'auto', 0],   
        textAlign: 'center',
    },

    message: {
        color: colors.boulder,
    },

    readMore: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
        position: 'fixed',
        top: 0,
        left: 0,
        transformOrigin: 'center',
        textAlign: 'center',
        backgroundColor: 'rgba(0, 0, 0, .5)',
        color: colors.white,
        opacity: 0,
        visibility: 'hidden',
        pointerEvents: 'none',
        transition: 'all ease-in-out .25s',
        zIndex: 11,

        '&.open': {
            opacity: 1,
            visibility: 'visible',
            pointerEvents: 'auto',
        },
    },

    readMoreInner: {
        width: '95%',
        maxWidth: 400,
        padding: spaces[3],
        transform: 'scale(.9)',
        backgroundColor: colors.black,
        transition: 'all ease-in-out .25s',

        '.open &': {
            transform: 'scale(1)',
        },
    },

    readMoreText: {
        ...typography.sm,
    },

    readMoreLink: {
        color: colors.blonde,
    },

    readMoreClose: {
        position: 'absolute',
        top: 0,
        left: '100%',
        transform: 'translate(-60%, -40%)',
    },
})
